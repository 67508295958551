import { Component, OnDestroy, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import {
  DmsClient,
  DatotekaDto,
  VrstaDatoteke,
  ResultOfDatotekaDto,
  IDatotekaTreeDto,
} from '@kodit/core/data-api';
import { Subscription } from 'rxjs';
import { DatotekaService } from '../datoteka-service';
import { DynamicDialogConfig, DynamicDialogService } from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { UnosDatotekeFormComponent } from '../file-manager-page/unos-datoteke-form/unos-datoteke-form.component';

@Component({
  selector: 'kodit-folder-preview',
  templateUrl: './folder-preview.component.html',
  styleUrls: ['./folder-preview.component.scss'],
})
export class FolderPreviewComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Config */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  innerHtml: SafeHtml | undefined;
  blobUrl!: string;
  jePdfOrImage: boolean | undefined = undefined;

  selectedFolder: IDatotekaTreeDto | undefined;

  childrenFolders: DatotekaDto[] | undefined;
  childrenDokuments: DatotekaDto[] | undefined;
  childrenDelovodniks: DatotekaDto[] | undefined;

  constructor(
    private _datotekaService: DatotekaService,
    private _dialogService: DynamicDialogService,
    private _client: DmsClient
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this._datotekaService.getSelectedDatoteka.subscribe((datoteka) => {
        if (datoteka && datoteka.data?.vrstaDatoteke === VrstaDatoteke.FOLDER) {
          this.selectedFolder = datoteka.data;
          this._getFolderForPreview(datoteka?.data?.id!);
        }
      })
    );

    this._subs.add(
      this._datotekaService.getChangeOccured.subscribe((value) => {
        if (value) {
          this._getFolderForPreview(this.selectedFolder?.id!);
          this._datotekaService.setChangeOccured = false;
        }
      })
    );
  }

  dodajFolder() {
    this.dialogConfig.data = {
      vrstaDatoteke: VrstaDatoteke.FOLDER,
      enableParentSelection: false,
      parentId: this.selectedFolder?.id,
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.customSubmitButton = { label: 'Kreiraj' } as any;
    this.dialogConfig.customCancelButton = { label: 'Otkaži' } as any;
    this.dialogConfig.header = 'NOVI FOLDER';
    this._dialogService.open(UnosDatotekeFormComponent, this.dialogConfig);
  }

  dodajDelovodnik() {}

  dodajDokument() {
     this.dialogConfig.data = {
       vrstaDatoteke: VrstaDatoteke.DOKUMENT,
       enableParentSelection: false,
       parentId: this.selectedFolder?.id,
     };
     this.dialogConfig.maximisable = false;
     this.dialogConfig.customSubmitButton = { label: 'Kreiraj' } as any;
     this.dialogConfig.customCancelButton = { label: 'Otkaži' } as any;
     this.dialogConfig.header = 'NOVI DOKUMENT';
     this._dialogService.open(UnosDatotekeFormComponent, this.dialogConfig);
  }

  private _getFolderForPreview(id: number) {
    this._subs.add(
      this._client
        .getFolderForPreview(id)
        .subscribe((res: ResultOfDatotekaDto) => {
          if (res.succeeded) {
            this._setChildrenArrays(res.data?.children);
          }
        })
    );
  }

  private _setChildrenArrays(children: DatotekaDto[] | undefined) {

    if (children) {
      this.childrenFolders = children.filter(
        (child) => child.vrsta === VrstaDatoteke.FOLDER
      );
      this.childrenDelovodniks = children.filter(
        (child) => child.vrsta === VrstaDatoteke.DELOVODNIK
      );
      this.childrenDokuments = children.filter(
        (child) => child.vrsta === VrstaDatoteke.DOKUMENT
      );
    }
  }

  ngOnDestroy(): void {
    this.jePdfOrImage = undefined;
    this._subs.unsubscribe();
  }
}
