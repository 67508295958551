import { Component, OnDestroy, OnInit } from '@angular/core';
import { DatotekaTreeDto, DmsClient, IDatotekaTreeDto, VrstaDatoteke } from '@kodit/core/data-api';
import { SelectItem, TreeNode } from 'primeng/api';
import { Subscription } from 'rxjs';
import { DatotekaService } from '../../../datoteka-service';

@Component({
  selector: 'kodit-datoteka-tree',
  templateUrl: './datoteka-tree.component.html',
  styleUrls: ['./datoteka-tree.component.scss'],
})
export class DatotekaTreeComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  datoteke: TreeNode<any>[] = [];
  layout: string = 'vertical';
  emptyMessage: string = 'Nema datoteka u bazi.';
  selectedDatoteka: TreeNode | undefined;
  selectedDatotekaData: DatotekaTreeDto | undefined;
  displayType: string = 'vertical';
  displayTypes: SelectItem[] = [];
  nodeId: number | undefined;
  pronadjen: boolean = false;

  constructor(
    private _client: DmsClient,
    private _datotekaService: DatotekaService
  ) {}
  ngOnInit(): void {
    this._loadTree();
    this._subs.add(
      this._datotekaService.getChangeOccured.subscribe((value) => {
        if (value) {
          this._loadTree();
          this._datotekaService.setChangeOccured = false;
        }
      })
    );

    this._subs.add(
      this._datotekaService.getSelectedDatoteka.subscribe((datoteka) => {
        this.selectedDatoteka = datoteka;
      })
    );
  }

  private _loadTree() {
    this._subs.add(
      this._client.getForTree().subscribe((res) => {
        if (!res || res.data?.datotekaTree?.length === 0) {
          return;
        }

        const filteredFolders = this.extractFirstLevelFolders(res.data?.datotekaTree);
        const folders : IDatotekaTreeDto[] = [];

        filteredFolders.forEach((folder, index) => {
          folders[index] = {
            id: folder.id,
            childrenDto: folder.childrenDto,
            naziv: folder.naziv,
            vrstaDatoteke:1,
          }
        });

        const temp: TreeNode<IDatotekaTreeDto>[] = [];

        this.datoteke = [];
        folders.forEach((d) =>
          temp.push(this._setDatotekaTree(d))
        );


        folders.forEach((folder,index)=> {
          this.datoteke.push({
            label:folder.naziv,
            data: temp[index].data,
            children:temp[index].children,
            icon : 'fa-solid fa-folder-closed',
            expanded: false,
            selectable: true,
          })
        });

      })
    );
  }

  extractFirstLevelFolders(json: DatotekaTreeDto[] | undefined): IDatotekaTreeDto[] {
   //Vracamo one sa prvog nivoa
    // @ts-ignore
    return json.filter(item => item.parentId === null);
  }

  nodeSelect(event: any) {
    event.node.expanded = true;
    this.selectedDatotekaData = event.node;
    this._datotekaService.setSelectedDatoteka = event.node;
  }

  nodeUnselect(event: any) {
    this.selectedDatotekaData = undefined;
  }

  // private _findNode(root: TreeNode<IDatotekaTreeDto>, id: number){
  //   if (root.data?.id === id) {
  //     this.pronadjen=true
  //     return root;
  //   }

  //   for(var i=0; i<root.children?.length! && this.pronadjen; i++){
  //     return this._findNode(root.children![i], id);
  //   }
  // }

  private _setDatotekaTree(
    datotekaTree: IDatotekaTreeDto
  ): TreeNode<IDatotekaTreeDto> {
    const result: TreeNode<IDatotekaTreeDto> = {
      label: datotekaTree.naziv,
      children: [],
      data: datotekaTree,
      selectable: true,
      icon: this._setIcon(datotekaTree.vrstaDatoteke!),
      expanded: false,
    };

    if (datotekaTree.childrenDto!.length > 0) {
      datotekaTree.childrenDto?.forEach((d) =>
        result.children!.push(this._setDatotekaTree(d))
      );
    }

    return result;
  }


  private _setIcon(vrstaDatoteke: VrstaDatoteke): string {
    var iconClass = '';

    switch (vrstaDatoteke) {
      case VrstaDatoteke.FOLDER:
        iconClass = 'fa-solid fa-folder-closed';
        break;
      case VrstaDatoteke.DOKUMENT:
        iconClass = 'fa-solid fa-file-lines';
        break;
      case VrstaDatoteke.DELOVODNIK:
        iconClass = 'fa-regular fa-table';
        break;
    }

    return iconClass;
  }
  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
